

    export default {
        meta: {
            title: 'Log In & Register'
        },
        data() {
            return {
                email: '',
                password: '',
            }
        },
        methods: {
            async login() {
                grecaptcha.enterprise.ready(async () => {
                    const token = await grecaptcha.enterprise.execute(this.$config.google_recaptcha_key, {action: 'LOGIN'});
                    try {
                        await this.$auth.loginWith('local', {data: {
                            email: this.email,
                            password: this.password,
                            recaptcha_token: token
                        }});
                        this.fetchIsPremiumBuyer();
                    } catch (e) {
                        if (e?.response?.data?.action === 'verify') {
                            await this.verifyLogin(e?.response?.data?.customer_id)
                        } else {
                            throw e;
                        }
                    }
                });
            },
            async fetchIsPremiumBuyer() {
                if (!this.$auth.loggedIn) return;

                try {
                    let isPremium;

                    const activeSubscriptions = await this.$axios.$get(this.$settings.API.getDocument,
                        {params: {with: 'operation', 'filter[mode]': 'my_subscriptions'}}
                    );

                    isPremium = activeSubscriptions.some(
                        subscription => subscription.operation.slug === this.$settings.premiumBuyerSlug
                    );

                    this.$store.dispatch('setIsPremium', isPremium);
                } catch (e) {
                    console.error(e);
                }
            },
            async verifyLogin(customer_id) {
                this.$nuxt.$emit('open_modal_verify_action', {
                    action: 'login',
                    customer_id: customer_id,
                    onVerify: async (verification_hash) => {
                        await this.$auth.loginWith('local', {data: {
                            email: this.email,
                            password: this.password,
                            verification_hash: verification_hash
                        }});
                    }
                })
            }
        }
    }
